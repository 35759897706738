<template>
  <div
    class="wrapper d-flex flex-column align-center"
    @click.stop
  >
    <v-menu
      :attach="attachEl"
      :left="displayToLeft"
      :top="displayToTop"
      :z-index="this.displayZIndex || 2"
      close-on-content-click
      content-class="background-translucid"
      min-width="max-content"
      origin="right top"
      ref="menu"
      transition="slide-y-transition"
      v-model="isOpen"
    >
      <template v-slot:activator="{ on }">
        <div
          class="d-flex flex-grow-1 align-center"
          v-on="on"
        >
          <v-badge
            dot
            right
            color="secondary"
            :value="showUpdate"
          >
            <calendar-date
              :date="updateAt"
              :showTime="showTime"
              class="ml-1"
            />
          </v-badge>
        </div>
      </template>
      <v-sheet
        class="background-plain ma-0 d-flex flex-column pa-3"
        ref="content"
      >
        <div
          class="d-flex flex-row align-center"
          v-if="showUpdate"
        >
          <span class="mr-1">{{$t('t.UpdateAt')}}</span>
          <calendar-date
            class="mr-1"
            :date="updateAt"
            :showTime="showTime"
          />
          <div
            v-if="value && value.updateBy"
            class="d-flex flex-row"
          >
            <span class="mr-2">{{$t('t.By')}}</span>
            <user-ref
              block-popup
              hide-group
              hide-email
              hide-phone
              :avatar-props="{xsmall: true}"
              :id="value.updateBy"
            />
          </div>
        </div>
        <div
          class="d-flex flex-row"
          :class="{'mt-3': showUpdate}"
        >
          <span class="mr-1">{{$t('t.CreateAt')}}</span>
          <calendar-date
            class="mr-1"
            :date="createAt"
            :showTime="showTime"
          />
          <div
            v-if="value && value.createBy"
            class="d-flex flex-row"
          >
            <span class="mr-2">{{$t('t.By')}}</span>
            <user-ref
              block-popup
              hide-group
              hide-email
              hide-phone
              :avatar-props="{xsmall: true}"
              :id="value.createBy"
            />
          </div>

        </div>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import DisplayableInContainer from '@/pages/search/mixins/displayable-in-container'

export default {
  mixins: [DisplayableInContainer],
  inheritAttrs: false,
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  computed: {
    showUpdate () {
      return this.updateAt !== this.createAt
    },
    updateAt () {
      return this.value.updateAt
    },
    createAt () {
      return this.value.createAt
    }
  },
  mounted () {
    // Close popup with 'esc' key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.isOpen = false
      }
    })
  },
  data () {
    return {
      showTime: true
    }
  },
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  height 100%
  position relative
</style>
